import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import {Button, FormControl, Form, Modal} from 'react-bootstrap';
import { createDemo } from "../../request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TrialForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            email: "",
            honeyPot: null,
            hasEmailError: false,
            success: false,
            isProcessing: false,
            showModal: false,
            fieldsError: false,
            serverError: false,
            finished: false
        }
    }

    handleInputChange = (e) => {
        if (e && e.target && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

        this.setState({
            hasEmailError: false
        });
    }

    handleHoneyPotChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            honeyPot: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isProcessing: true,
            finished: false,
            hasEmailError: false,
            fieldsError: false,
            serverError: false
        });

        if (!this.state.email) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasEmailError: true
            });


        } else if (!this.validateEmail(this.state.email)) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasEmailError: true
            });

        } else {

            if (!this.state.honeyPot) {

                createDemo({email: this.state.email}).then(() => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        email: "",
                        finished: true,
                        serverError: false
                    });

                    window.dataLayer.push('event', 'conversion', {'send_to': 'AW-970402719/wCTdCNTc0ZEYEJ_X3M4D'});

                }).catch((e) => {

                    this.setState({
                        isProcessing: false,
                        showModal: true,
                        finished: false,
                        serverError: true
                    });

                });

            } else {

                this.setState({
                    isProcessing: false,
                    showModal: true,
                    finished: false,
                    serverError: true
                });

            }

        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    trialFormTitle = () => {
        if (this.props?.content?.title) {
            return (
              <div className={`title`}>{this.props.content.title}</div>
            );
        } else {
            return (
              <div className={`title`}><FormattedMessage id="components.trialForm.title" /></div>
            );
        }
    }

    trialFormText = () => {
        if (this.props?.content?.text) {
            return (
              <div className={`text`}>{this.props.content.text}</div>
            );
        } else {
            return (
              <div className={`text`} dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "components.trialForm.text" }) }}></div>
            );
        }
    }


    render() {
        return (
            <div className={`trial-form`}>
                {this.trialFormTitle()}
                {this.trialFormText()}
                <div className={`form`}>
                    <Form className={`form-area`} onSubmit={this.handleSubmit}>
                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.trialForm.email.label" /></Form.Label>
                            <FormControl type="text" value={this.state.email} name={`email`} className="form-field" onChange={this.handleInputChange} />

                            <div style={{position: "absolute", left: "-9999px", overflow: "hidden"}} aria-hidden="true">
                                <input className="form-field" placeholder={this.props.intl.formatMessage({ id: "components.trialForm.email.placeholder" })} type="text" onChange={this.handleHoneyPotChange} />
                            </div>

                            {this.state.hasEmailError === true &&
                            <>
                                <div className={`feedback-text error`}><FormattedMessage id="components.trialForm.email.error" /></div>
                                <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                            </>
                            }
                        </div>
                        <Button className={`form-button`} variant="secondary" type="submit" block disabled={this.state.isProcessing}><FormattedMessage id="components.trialForm.button" /></Button>
                    </Form>

                    <Modal show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.fieldsError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-triangle" />
                                    <FormattedMessage id="components.trialForm.alerts.titles.warning" />
                                </>
                                }
                                {this.state.serverError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-circle" />
                                    <FormattedMessage id="components.trialForm.alerts.titles.error" />
                                </>
                                }
                                {this.state.finished === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="check-circle" />
                                    <FormattedMessage id="components.trialForm.alerts.titles.success" />
                                </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={`text-center m-3`}>
                                {this.state.fieldsError === true &&
                                <FormattedMessage id="components.trialForm.alerts.error" />
                                }
                                {this.state.serverError === true &&
                                <FormattedMessage id="components.trialForm.alerts.serverError" />
                                }
                                {this.state.finished === true &&
                                <FormattedMessage id="components.trialForm.alerts.success" />
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default injectIntl(TrialForm)
