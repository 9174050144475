import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import LayoutBlack from "../../../components/layout-black"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import TrialData from "../../../content/demo/bf/index.json"
import TickerData from "../../../content/home/ticker.json";
import TrialForm from "../../../components/forms/trial-form";
import Testimonials from "../../../components/home/testimonials";
import ClientsTicker from "../../../components/misc/clients-ticker";

const DemoTrialPage = ({ intl, location }) => (
    <LayoutBlack>
        <Seo lang={intl.locale} title={TrialData[intl.locale].title} description={TrialData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`black`} content={TrialData[intl.locale]} />
        <div className={`trial`}>
            <div className={`top-overflow-wrapper`}>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <div className={`trial-form-box`}>
                                <TrialForm />
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Testimonials />
                    <ClientsTicker content={TickerData[intl.locale]} />
                </Container>
            </div>
        </div>
    </LayoutBlack>
)

export default injectIntl(DemoTrialPage)
